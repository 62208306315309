<template>
    <div class="wrap-header">
        <div class="header">
            <div class="btn-open-side" :class="{ changeArrow: status }" @click="openToggle">
                <img src="@/assets/icon_cms/arrow.svg" alt="" class="icon-input" />
            </div>
            <div class="brand">
                <div class="title-page">
                    {{ $route.meta.breadcrumb }}
                </div>
                <!-- <b-breadcrumb :items="crumbs" class="breadcrumb"></b-breadcrumb> -->
                <breadcrumbs v-if="$route.path != '/'"></breadcrumbs>
            </div>
            <!-- <div class="live-time">{{ currentDate }} {{ liveTime }}</div> -->
            <div class="user">
                <dropdown>
                    <template slot="toggler">
                        <!-- <img src="@/assets/icon_cms/notif.svg" alt="" class="mr-2" /> -->
                        {{ username }}
                        <!-- <img src="@/assets/icon_cms/profil.svg" alt="" /> -->
                        <i class="fa fa-sort-down"></i>
                    </template>
                    <dropdown-content>
                        <router-link to="/profil">
                            <div class="item-dropdown my-1 px-3 py-2">Profil</div>
                        </router-link>
                        <div class="item-dropdown my-1 px-3 py-2" @click="logout">Logout</div>
                    </dropdown-content>
                </dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Dropdown from "./dropdown/Dropdown.vue";
import DropdownContent from "./dropdown/DropdownContent.vue";
import Breadcrumbs from "./Breadcrumbs.vue";

export default {
    components: {
        Dropdown,
        DropdownContent,
        Breadcrumbs,
    },
    data() {
        return {
            dataToggle: false,
            status: false,
            username: "",
            liveTime: null,
            currentDate: null,
        };
    },
    mounted() {
        this.username = sessionStorage.getItem("username");
        let date = new Date();
        this.currentDate = moment(date).format("dddd, DD MMMM YYYY");
    },
    created() {
        setInterval(() => {
            this.showLiveTime();
        }, 1000);
    },
    computed: {
        crumbs() {
            let pathArray = this.$route.path.split("/");
            pathArray.shift();
            let breadcrumsbs = pathArray.reduce((breadcrumsbArray, path, idx) => {
                breadcrumsbArray.push({
                    path: path,
                    to: breadcrumsbArray[idx - 1] ? "/" + breadcrumsbArray[idx - 1].path + "/" + path : "/" + path,
                    text: this.$route.matched[idx + 1].meta.breadCrumb || path,
                });
                return breadcrumsbArray;
            }, []);
            return breadcrumsbs;
        },
    },
    methods: {
        showLiveTime() {
            let date = new Date();
            this.liveTime = moment(date).format("HH:mm:ss");
        },
        toggle() {
            this.dataToggle = !this.dataToggle;
        },
        logout() {
            sessionStorage.clear();
            delete axios.defaults.headers.common["Authorization"];
            this.$router.go("/login");
        },
        openToggle() {
            this.status = !this.status;
            this.$emit("toggleSide", this.status);
            this.$root.$emit("toggleSide", this.status);
        },
    },
};
</script>

<style scoped>
.wrap-header {
    height: 100%;
}

/* .mini {
  width: calc(100% - 70px);
} */

.live-time {
    font-size: 14px;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
}

.title-page div {
    font-size: 1.25rem;
    margin-bottom: -0.5rem;
}

.btn-open-side {
    position: absolute;
    background-color: #005f7f;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -0.85rem;
    transform: rotate(-90deg);
    transition: ease-in-out 0.5s;
    cursor: pointer;
}

.changeArrow {
    transform: rotate(90deg);
}

/* .btn-open-side img {
    
} */

@keyframes changewidth {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(3px);
    }
}

.btn-open-side img {
    animation-duration: 1s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.item-dropdown {
    line-height: 1.5;
}

.content-dropdown {
    right: 0;
    top: 2.5em;
}

.user {
    position: relative;
}

.user div {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #2c3e50;
}

.active-dropdown {
    display: inline-block;
}

.dropdown-header {
    position: absolute;
    right: 0;
    border: solid 1px rgba(112, 112, 112, 0.4);
    -webkit-box-shadow: 0 0px 5px 0 rgba(10, 10, 10, 0.76);
    box-shadow: 0 0px 2px 0 rgba(1, 2, 5, 0.55);
    width: 170px;
    border-radius: 5px;
    background: #fff;
}

.dropdown-header li {
    color: #2c3e50;
    cursor: pointer;
    /* padding: 10px 15px; */
    line-height: 35px;
}

.dropdown-header li:hover {
    color: #6495ed;
}

.humberger-icon {
    position: absolute;
    left: 20px;
    top: 25px;
}
.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    height: 22px;
    width: 22px;
}
.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #005f7f;
}

.breadcrumb {
    background-color: unset;
    padding: unset;
    margin: unset;
    height: 5rem;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}
.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type="checkbox"] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

/* input[type=checkbox]:checked ~  */
.sidebarIconToggle > .centerLine {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}
/* input[type=checkbox]:checked ~  */
.sidebarIconToggle > .diagonaltop {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
/* input[type=checkbox]:checked ~  */
.sidebarIconToggle > .diagonalbottom {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}
</style>
