<template>
    <div class="wrap-layout">
        <div class="layout">
            <div class="wrap-sidebar" :class="{ 'hide-side': status }" :miniSide="status">
                <Sidebar v-show="role != 5 && role != 15" />
                <SidebarOperator v-show="role == 5" />
                <SidebarJfx v-show="role == 15" />
            </div>
            <div class="wrap-main-content" :class="{ 'mini-side': status }">
                <header class="header">
                    <Header @toggleSide="openToggle" />
                </header>
                <main>
                    <router-view></router-view>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import SidebarOperator from "../components/SidebarOperator.vue";
import SidebarJfx from "../components/SidebarJfx.vue";
import Header from "../components/Header.vue";

export default {
    components: {
        Sidebar,
        Header,
        SidebarOperator,
        SidebarJfx,
    },
    data() {
        return {
            status: false,
            role: "",
        };
    },
    mounted() {
        this.role = sessionStorage.getItem("role");
    },
    methods: {
        openToggle(e) {
            this.status = e;
        },
    },
};
</script>
<style scoped>
.wrap-layout {
    min-height: 100vh;
}
.layout {
    height: 100%;
    display: flex;
    position: relative;
}

.header {
    height: 70px;
    padding: 5px 40px;
    background: #fff;
    color: #2c3e50;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    position: fixed;
    width: calc(100% - 80px);
    z-index: 9;
}

.wrap-sidebar {
    height: 100%;
    width: 80px;
    background: #005f7f;
    color: #fff;
    position: fixed;
    transition: ease-out 0.5s;
    z-index: 1;
}

.hide-side {
    width: 240px;
}

.wrap-main-content {
    padding-left: 80px;
    transition: ease-out 0.5s;
    width: 100%;
}

.mini-side {
    padding-left: 240px;
}

.mini-side .header {
    width: calc(100% - 240px);
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #005f7f;
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 50px;
    display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
}

main {
    margin-top: 70px;
}

@media only screen and (max-width: 600px) {
    .wrap-sidebar {
        margin-left: -240px;
    }
    .wrap-main-content {
        padding: unset;
        margin-left: 0;
    }
    .hide-side {
        margin-left: 0;
        width: 240px;
        z-index: 1;
    }
    .header {
        width: 100%;
    }
}
</style>
