<template>
    <div class="breadcrumbs">
        <ul>
            <li v-for="(breadcrumb, i) in breadcrumbsList" :key="i">
                <span>{{ breadcrumb.text }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbsList: [],
        };
    },
    watch: {
        $route() {
            this.updateList();
        },
    },
    mounted() {
        // console.log(this.$route);
        this.updateList();
    },
    computed: {
        // crumbs() {
        //     let pathArray = this.$route.path.split("/");
        //     pathArray.shift();
        //     let breadcrumsbs = pathArray.reduce((breadcrumsbArray, path, idx) => {
        //         breadcrumsbArray.push({
        //             path: path,
        //             to: breadcrumsbArray[idx - 1] ? "/" + breadcrumsbArray[idx - 1].path + "/" + path : "/" + path,
        //             text: this.$route.meta.breadCrumb || "",
        //         });
        //         return breadcrumsbArray;
        //     }, []);
        //     return breadcrumsbs;
        // },
    },
    methods: {
        updateList() {
            let pathArray = this.$route.path.split("/");
            pathArray.shift();
            let breadcrumsbs = pathArray.reduce((breadcrumsbArray, path, idx) => {
                breadcrumsbArray.push({
                    path: path,
                    to: breadcrumsbArray[idx - 1] ? "/" + breadcrumsbArray[idx - 1].path + "/" + path : "/" + path,
                    text: this.$route.matched[idx + 1].meta.breadcrumb || "",
                });
                return breadcrumsbArray;
            }, []);

            this.breadcrumbsList = breadcrumsbs;
        },
    },
};
</script>

<style scoped>
ul {
    display: flex;
    width: auto;
    height: 10px;
    font-size: 0.8em;
    cursor: default;
    align-items: center;
    float: left;
    margin: unset;
}

ul > li:not(:last-child)::after {
    content: "/";
    float: right;
    font-size: 0.8em;
    margin: 0 0.5em;
    cursor: default;
}
</style>
