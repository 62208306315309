<template>
    <div class="sidebar" :class="{ sidebarfull: status }">
        <div class="title">
            <div class="logo-img" :class="{ 'mini-logo-img': !status }">
                <div v-show="role == 1 || role == 4">
                    <img src="@/assets/kbi.png" alt="" v-if="status" />
                    <img src="@/assets/kbi_mini.png" alt="" v-else />
                </div>
                <img src="@/assets/ABX-grey.png" alt="" v-show="role == 2" />
                <!-- <img src="@/assets/logo_agi.png" alt="" v-show="role == 13" class="bagi" /> -->
            </div>
        </div>
        <div class="menu" :class="{ 'mini-menu': !status }">
            <ul class="nav-menu">
                <li class="title-list-menu">
                    <div v-if="!status" class="text-center">Clearing</div>
                    <div v-else>Clearing Management</div>
                </li>
                <router-link to="/" exact>
                    <li class="list-menu">
                        <div>
                            <img src="@/assets/icon_cms/clearing/1c.svg" alt="" class="icon main-icon" />
                            <span>Clearing & Settlement</span>
                        </div>
                        <ul class="list-sub-menu">
                            <li class="title-sub-menu">Clearing & Settlement</li>
                        </ul>
                    </li>
                </router-link>
                <li class="list-menu" v-show="role == 1 || role == 2 || role == 4">
                    <div @click="toggle(1)" class="sub-menu">
                        <img src="@/assets/icon_cms/cash/1c.svg" alt="" class="icon main-icon" />
                        <span>Cash Management</span>
                    </div>
                    <ul class="list-sub-menu">
                        <li class="title-sub-menu">Cash Management</li>
                        <router-link to="/cash-management/cash-in-out">
                            <li>
                                <img src="@/assets/icon_cms/withdrawal/1w.svg" alt="" class="icon" />
                                In & Out
                            </li>
                        </router-link>
                        <router-link to="/cash-management/portofolio">
                            <li>
                                <img src="@/assets/icon_cms/portfolio/1w.svg" alt="" class="icon" />
                                Portfolio
                            </li>
                        </router-link>
                        <router-link to="/cash-management/chart">
                            <li>
                                <img src="@/assets/icon_cms/chart/1f.svg" alt="" class="icon" />
                                Chart
                            </li>
                        </router-link>
                        <router-link to="/cash-management/participants">
                            <li>
                                <img src="@/assets/icon_cms/users.svg" alt="user" class="icon users-icon" />
                                Participants
                            </li>
                        </router-link>
                    </ul>
                </li>
                <li class="title-list-menu">
                    <div v-if="!status" class="text-center">Vault</div>
                    <div v-else>Vault Management</div>
                </li>
                <li class="list-menu" v-show="role != 4">
                    <div @click="toggle(2)" class="sub-menu">
                        <img src="@/assets/icon_cms/vault/4c.svg" alt="" class="icon main-icon" />
                        <span>On Exchange</span>
                    </div>
                    <ul class="list-sub-menu">
                        <li class="title-sub-menu">On Exchange</li>
                        <router-link to="/on-exchange/deposit">
                            <li><img src="@/assets/icon_cms/deposit/1w.svg" alt="" class="icon" />Deposit</li>
                        </router-link>
                        <router-link to="/on-exchange/withdrawal">
                            <li><img src="@/assets/icon_cms/withdrawal/1w.svg" alt="" class="icon" />Withdrawal</li>
                        </router-link>
                        <router-link to="/on-exchange/inventory">
                            <li><img src="@/assets/icon_cms/inventory/1w.svg" alt="" class="icon vertical" />Inventory</li>
                        </router-link>
                        <!-- <router-link to="/history">
                            <li><img src="@/assets/icon_cms/history/1w.svg" alt="" class="icon vertical" />History</li>
                        </router-link> -->
                    </ul>
                </li>
                <li class="list-menu" v-show="role != 4">
                    <div @click="toggle(3)" class="sub-menu">
                        <img src="@/assets/icon_cms/vault/3c.svg" alt="" class="icon main-icon" />
                        <span>Off Exchange</span>
                    </div>
                    <ul class="list-sub-menu">
                        <li class="title-sub-menu">Off Exchange</li>
                        <router-link to="/off-exchange/deposit">
                            <li><img src="@/assets/icon_cms/deposit/1w.svg" alt="" class="icon" />Deposit</li>
                        </router-link>
                        <router-link to="/off-exchange/withdrawal">
                            <li><img src="@/assets/icon_cms/withdrawal/1w.svg" alt="" class="icon" />Withdrawal</li>
                        </router-link>
                        <router-link to="/off-exchange/inventory">
                            <li><img src="@/assets/icon_cms/inventory/1w.svg" alt="" class="icon vertical" />Inventory</li>
                        </router-link>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- <div class="footer">logout</div> -->
    </div>
</template>

<script>
export default {
    props: {
        miniSide: Boolean,
    },
    data() {
        return {
            status: false,
            role: "",
        };
    },
    mounted() {
        this.role = sessionStorage.getItem("role");
        this.$root.$on("toggleSide", (data) => {
            this.status = data;
        });
    },
    methods: {
        toggle(e) {
            if (this.status) {
                var elems = document.getElementsByClassName("list-sub-menu");
                if (elems[e].classList.value === "list-sub-menu") {
                    elems[e].classList.add("active-menu");
                } else {
                    elems[e].classList = "list-sub-menu";
                }
            }
        },
    },
};
</script>

<style scoped>
a .router-link-exact-active,
.router-link-active li {
    background: #024f68;
}
.title {
    height: 70px;
    padding: 7px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    background: #025c7a;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 12%);
}

.sidebarfull {
    height: 100%;
    overflow-y: scroll;
}

.logo-img {
    width: 7rem;
    margin: 0 auto;
    transition: ease-out 0.5s;
}

.users-icon {
    filter: invert(100%) sepia(6%) saturate(180%) hue-rotate(232deg) brightness(114%) contrast(100%);
}

.mini-logo-img {
    width: 3rem;
    transition: ease-out 0.5s;
}

.logo-img img {
    width: 100%;
}

.bagi {
    height: 38px;
    margin-left: -32px;
    width: 163px !important;
}

.menu {
    margin-top: 50px;
}

ul {
    padding: 0 5px;
    transition: 0.5s;
}

/* .nav-menu {
  position: relative;
} */

.menu.mini-menu .nav-menu {
    overflow: visible;
}

.menu.mini-menu .list-menu div {
    justify-content: center;
}

.menu.mini-menu .list-menu div span {
    display: none;
}

.list-menu {
    border-radius: 5px;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 5px 0;
    position: relative;
}

.menu .list-menu .list-sub-menu {
    display: none;
}

.menu .list-menu .list-sub-menu.active-menu {
    display: block;
}

.menu.mini-menu .list-menu .list-sub-menu li {
    white-space: nowrap;
    font-size: 13px;
    opacity: 0.6;
}
.menu.mini-menu .list-menu .list-sub-menu li:hover {
    white-space: nowrap;
    font-size: 13px;
    opacity: 1;
}

.menu.mini-menu .list-menu .list-sub-menu img {
    display: none;
}

.menu.mini-menu .list-menu .list-sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    background: #024f68;
    padding: 0;
    border-radius: 0 5px 5px 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
    display: block;
}

.menu.mini-menu .list-menu:hover .list-sub-menu {
    top: 5px;
    opacity: 1;
    pointer-events: auto;
    padding: 5px 10px;
}

.menu .list-menu .list-sub-menu .title-sub-menu {
    display: none;
}

.menu.mini-menu .list-menu .list-sub-menu .title-sub-menu {
    display: block;
    opacity: 1;
}

.list-menu div,
.list-sub-menu li {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.list-menu div {
    display: flex;
    align-items: center;
}

/* .sub-menu::after {
  font-family: "Ionicons";
  content: "\f35f";
  position: absolute;
  right: 10px;
} */

.mini-sub-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 0px;
    margin-bottom: 10px;
    gap: 5px;
}

.list-menu div:hover,
.list-sub-menu li:hover {
    background: #024f68;
}

a {
    color: #fff;
}

.list-sub-menu {
    padding: 0 0 0 20px;
    /* display: none; */
}
.menu.mini-menu .list-menu .icon {
    margin: unset;
}
.icon {
    width: 15px;
    margin-right: 10px;
    vertical-align: text-bottom;
}

.vertical {
    width: 12px;
    margin-left: 3px;
}

.holders {
    width: 17px;
}

.main-icon {
    width: 27px;
}

.title-list-menu {
    margin: 1rem 0.5rem 0.2rem;
    color: #9ea5ab;
    font-size: 12px;
    font-weight: bold;
}
</style>
