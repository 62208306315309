<template>
    <div class="sidebar">
        <div class="title">
            CMS
            <!-- <span v-show="status">Clearinghouse Management System</span> -->
        </div>
        <div class="menu" :class="{ 'mini-menu': !status }">
            <ul class="nav-menu">
                <!-- <li class="list-menu"> -->
                <!-- <div class="sub-menu" v-show="status">Bullion Management</div>
          <div class="sub-menu" v-show="!status">BM</div>
          <ul class="list-sub-menu"> -->
                <router-link to="/operator-deposite" v-show="role == 5">
                    <li class="list-menu">
                        <div>
                            <img src="@/assets/icon_cms/deposit/1w.svg" alt="" class="icon" />
                            <span>Deposit</span>
                        </div>
                        <ul class="list-sub-menu">
                            <li class="title-sub-menu">Deposit</li>
                        </ul>
                    </li>
                </router-link>
                <router-link to="/operator-withdrawal">
                    <li class="list-menu">
                        <div>
                            <img src="@/assets/icon_cms/withdrawal/1w.svg" alt="" class="icon" />
                            <span>Withdrawal</span>
                        </div>
                        <ul class="list-sub-menu">
                            <li class="title-sub-menu">Withdrawal</li>
                        </ul>
                    </li>
                </router-link>
                <!-- </ul> -->
                <!-- </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        miniSide: Boolean,
    },
    data() {
        return {
            status: false,
            role: "",
        };
    },
    mounted() {
        // console.log(this.miniSide);
        this.role = sessionStorage.getItem("role");
        this.$root.$on("toggleSide", (data) => {
            this.status = data;
        });
    },
    methods: {
        toggle(e) {
            var elems = document.getElementsByClassName("list-sub-menu");
            if (elems[e].classList.value === "list-sub-menu") {
                elems[e].classList.add("active-menu");
            } else {
                elems[e].classList = "list-sub-menu";
            }
        },
    },
};
</script>

<style scoped>
a .router-link-exact-active,
.router-link-active li {
    background: #024f68;
}
.title {
    height: 70px;
    padding: 7px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    background: #025c7a;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 12%);
}

.menu {
    margin-top: 50px;
}

.title div {
    font-size: 11px;
    margin-top: -10px;
}

ul {
    padding: 0 5px;
    transition: 0.5s;
}

.menu.mini-menu .nav-menu {
    overflow: visible;
}

.menu.mini-menu .list-menu div {
    justify-content: center;
}

.menu.mini-menu .list-menu div span {
    display: none;
}

.list-menu {
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 5px 0;
    position: relative;
}

.menu .list-menu .list-sub-menu {
    display: none;
}

.menu .list-menu .list-sub-menu.active-menu {
    display: block;
}

.menu.mini-menu .list-menu .list-sub-menu li {
    white-space: nowrap;
    font-size: 14px;
    opacity: 0.6;
}
.menu.mini-menu .list-menu .list-sub-menu li:hover {
    white-space: nowrap;
    font-size: 14px;
    opacity: 1;
}

.menu.mini-menu .list-menu .list-sub-menu img {
    display: none;
}

.menu.mini-menu .list-menu .list-sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    background: #024f68;
    padding: 0;
    border-radius: 0 5px 5px 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
    display: block;
}

.menu.mini-menu .list-menu:hover .list-sub-menu {
    top: 5px;
    opacity: 1;
    pointer-events: auto;
    padding: 5px 10px;
}

.menu .list-menu .list-sub-menu .title-sub-menu {
    display: none;
}

.menu.mini-menu .list-menu .list-sub-menu .title-sub-menu {
    display: block;
    opacity: 1;
}

/* .mini-nav-menu .list-sub-menu,
.mini-nav-menu .list-sub-menu li {
  padding: 0;
} */

.list-menu div,
.list-sub-menu li {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.list-menu div {
    display: flex;
    align-items: center;
}

.list-menu div:hover,
.list-sub-menu li:hover {
    background: #024f68;
}

a {
    color: #fff;
}

.list-sub-menu {
    padding: 0 0 0 20px;
}

.list-sub-menu li {
    text-decoration: none;
}

.active-menu {
    display: block;
}

.icon {
    width: 20px;
    vertical-align: text-bottom;
}
</style>
